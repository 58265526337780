.Pagination {
  display: flex;
  margin: 0 auto;
  gap: 0.6rem;

  @media only screen and (min-width: 400px) {
    gap: 0.8rem;
  }

  span,
  a {
    display: flex;
    width: 3.2rem;
    height: 3.2rem;
    font-size: 1.8rem;
    align-items: center;
    justify-content: center;
    color: var(--color-dark-1);
    border-radius: var(--border-radius-1);
    text-align: center;
  }

  a[aria-current='page'] {
    background-color: var(--color-primary-1);
    color: #fff;
  }

  a[rel='prev'],
  a[rel='next'] {
    text-indent: -9999px;
    mask-size: 22px;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: var(--color-primary-1);
  }

  a[rel='prev'] {
    mask-image: url(/svg/icon_arrow_back.svg);
  }

  a[rel='next'] {
    mask-image: url(/svg/icon_arrow_forward.svg);
  }
}
