.CardWithImage {
  background-color: #fff;
  box-shadow: var(--box-shadow-1);
  border-radius: var(--border-radius-1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  height: 100%;

  h2 {
    font-weight: normal;
    font-size: 2.2rem;
  }

  picture {
    display: block;
    background-color: #aaa;
    border-radius: 0;
    overflow: hidden;
    align-self: self-start;
    height: 100%;
    width: 100%;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    aspect-ratio: 3 / 2;
  }

  > div {
    padding: var(--spacing-2);
    display: flex;
    gap: var(--spacing-3);
    flex-direction: column;
    align-items: flex-start;
  }
}

@container (min-width: 620px) {
  .CardWithImage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-3);
    align-items: center;

    > div {
      padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) 0;
    }

    &.image-right > div {
      padding: var(--spacing-2) 0 var(--spacing-2) var(--spacing-2);
    }
  }
}

@container (min-width: 720px) {
  .CardWithImage {
    gap: var(--spacing-4);

    > div {
      padding: var(--spacing-3) var(--spacing-3) var(--spacing-3) 0;
    }

    &.image-right > div {
      padding: var(--spacing-3) 0 var(--spacing-3) var(--spacing-3);
    }
  }
}
