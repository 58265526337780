@import '../../styles/variables';
@import '../../styles/util';

.Item {
  text-align: left;
  background-color: #fff;
  border-radius: var(--border-radius-1);
  box-shadow: var(--box-shadow-1);
  overflow: hidden;
  display: grid;
  grid-template-columns: 13.6rem auto;
  height: 100%;

  picture {
    background-color: #c9d2ba;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    height: 13.6rem;
    padding: 0.8rem;
  }

  img {
    flex-shrink: 0;
    object-fit: contain;
  }
}

.ItemBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.4rem 1.6rem;

  h3 {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 500;
    padding-bottom: var(--spacing-1);
  }

  p {
    font-size: 1.4rem;
    @include line-clamp(1);
  }

  a {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
  }

  div:last-child {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (width >= $tablet-portrait) {
  .ItemBody {
    padding: 1.6rem 2rem;
  }
}
