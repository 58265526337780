.Card {
  background-color: #fff;
  box-shadow: var(--box-shadow-1);
  border-radius: var(--border-radius-1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    font-weight: normal;
    font-weight: 500;
    font-size: 2rem;
  }

  > div {
    padding: var(--spacing-2);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    align-items: flex-start;

    > a {
      font-weight: bold;
    }
  }

  &.align-center {
    text-align: center;

    > div {
      align-items: center;
      padding: var(--spacing-3);
    }
  }

  &.align-right {
    text-align: right;
  }

  &.theme-orange,
  &.theme-teal,
  &.theme-blue {
    --color-1: hsl(182, 44%, 61%);
    --color-2: hsl(182, 44%, 50%);

    background-image: linear-gradient(
        var(--angle, 170deg),
        rgba(255, 255, 255, var(--alpha)) 0%,
        rgba(255, 255, 255, var(--alpha)) 50%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(at top left, var(--color-1), var(--color-2));

    > div > a {
      color: #fff;
    }
  }

  &.theme-orange {
    --color-1: hsl(40, 100%, 48%);
    --color-2: hsl(23, 98%, 46%);
    --shadow: hsla(23, 100%, 23%, 0.4);
  }

  &.theme-teal {
    --color-1: hsl(182, 44%, 61%);
    --color-2: hsl(182, 44%, 55%);
    --shadow: hsla(182, 100%, 15%, 0.4);
  }

  &.theme-blue {
    --color-1: hsl(195, 90%, 19%);
    --color-2: hsl(197, 100%, 16%);
    --shadow: hsla(197, 100%, 8%, 0.4);
    --alpha: 0.06;

    color: #fff;

    > div > a {
      color: var(--color-primary-1);
    }
  }
}
