.TextWithImage {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  picture {
    display: block;
    background-color: #aaa;
    border-radius: 0;
    overflow: hidden;
    align-self: self-start;
  }

  h2 {
    font-weight: 400;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    aspect-ratio: 3 / 2;
  }

  > div {
    display: grid;
    gap: var(--spacing-2);
  }
}

@container (min-width: 580px) {
  .TextWithImage {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: var(--spacing-4);
    align-items: flex-start;

    &.image-right {
      grid-template-columns: 2fr 1fr;
    }
  }
}
