@import '../../styles/variables';

.Item {
  background-color: #fff;
  border-radius: var(--border-radius-1);
  box-shadow: var(--box-shadow-1);
  padding: var(--spacing-2);
  gap: var(--spacing-2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;

  img {
    width: 8rem;
    height: 8rem;
    float: right;
    margin-left: 1.6rem;
  }

  h3 {
    font-size: 1.8rem;
    padding-bottom: 0.2rem;
    hyphens: auto;
  }

  div a {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
  }
}
