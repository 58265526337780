@import '../../styles/variables';
@import '../../styles/util';

.Item {
  --height: 5.6rem;

  display: grid;
  grid-template-columns: 4.8rem var(--height) auto 3.6rem;
  align-items: center;
  background-color: #fff;
  border-radius: var(--border-radius-1);
  box-shadow: var(--box-shadow-1);
  min-height: var(--height);

  img {
    border-radius: var(--border-radius-1);
    box-shadow: var(--box-shadow-1);
    aspect-ratio: 1 / 1;
  }

  time {
    font-size: 1.1rem;
    text-align: center;
  }

  .Info {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 1rem;
    padding: 0.2rem 0;

    h3,
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 20px;
    }
  }
}

@media only screen and (width >= 560px) {
  .Item {
    --height: 6.4rem;
    grid-template-columns: var(--height) var(--height) auto 4.8rem;

    time {
      font-size: 1.4rem;
    }

    .Info {
      margin: 0 1.4rem;

      h3,
      p {
        line-height: 24px;
      }
    }
  }
}
