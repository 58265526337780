.Player {
  background-color: #fff;
  box-shadow: var(--box-shadow-1);
  border-radius: var(--border-radius-1);
  position: relative;
  padding: var(--spacing-2);
  gap: var(--spacing-2);
  display: grid;
}

.MainStation {
  line-height: 2rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  button {
    align-self: center;
  }

  strong {
    text-transform: uppercase;
  }
}

.Station {
  display: grid;
  grid-template-columns: 4rem 1fr 2.4rem;
  align-items: center;
  gap: var(--spacing-1);
  border-top: 1px solid #c7cdd6;
  padding: var(--spacing-1) 0;
}
