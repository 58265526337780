@import '../../styles/variables';
@import '../../styles/util';

.Item {
  text-align: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none;
  }

  img {
    transition: scale 300ms ease-in-out;
    scale: 1;
    aspect-ratio: 1 / 1;
    height: auto;
    width: 100%;
  }

  > a {
    aspect-ratio: 1 / 1;
    box-shadow: var(--box-shadow-1);
    border-radius: var(--border-radius-1);
    overflow: hidden;
    isolation: isolate;
    width: 100%;
    background-color: #ddd;

    &:hover img {
      scale: 1.05;
    }

    &:focus-visible img {
      scale: 1.05;
    }
  }

  button,
  strong {
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 400;
    background-color: var(--color-secondary-1);
    display: flex;
    color: #fff;
    border-radius: 3rem;
    box-shadow: var(--box-shadow-2);
    padding: 0 1.6rem;
    margin-top: -2.2rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    min-height: 4.4rem;
    text-align: center;
    justify-content: center;
    gap: 1.6rem;
    position: relative;
  }

  @media only screen and (width >= $tablet-portrait) {
    button,
    strong {
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
  }

  strong[aria-hidden] {
    visibility: hidden;
  }

  button {
    background-color: var(--color-primary-1);
    width: auto;
    padding-right: 0.8rem;

    // Play button
    em {
      width: 2.4rem;
      height: 2.4rem;
      background-color: #fff;
      border-radius: 2.4rem;
      overflow: hidden;
      color: #000;
      text-indent: -9999px;
      padding-left: 0.2rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-left: 0.9rem solid var(--color-primary-1);
        border-bottom: 0.5rem solid transparent;
      }
    }
  }

  h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding-bottom: 0.2rem;

    a {
      text-decoration: none;
      color: var(--color-dark-1);
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}
