.Carousel {
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;
  max-width: var(--max-inner-width);

  img {
    aspect-ratio: 16 / 9;
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;

    transform: translate(0%);
    transition: transform 300ms ease-in-out;
  }
}

.CarouselPrev,
.CarouselNext {
  position: absolute;
  bottom: var(--spacing-1);
  width: 4rem;
  height: 4rem;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.6rem 1.6rem;
  background-image: url(/svg/icon_arrow_left.svg);
  text-indent: -9999px;
  border-radius: 4rem;
  box-shadow: var(--box-shadow-2);
  opacity: 1;
  transition: opacity 300ms ease-in-out;

  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }
}

.CarouselPrev {
  left: var(--spacing-1);
}

.CarouselNext {
  right: var(--spacing-1);
  background-image: url(/svg/icon_arrow_right.svg);
}
