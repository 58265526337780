@import '../../styles/variables';

.EmployeeCard {
  display: block;
  background-color: #fff;
  text-align: start;
  box-shadow: var(--box-shadow-1);
  border-radius: var(--border-radius-1);
  overflow: hidden;
  padding: var(--spacing-2);
  gap: var(--spacing-2);
  display: grid;

  .EmployeeFunction {
    font-size: 1.4rem;
    font-style: italic;
  }
}

.EmployeeCardBody {
  display: grid;
  width: 100%;
  gap: var(--spacing-2);

  picture,
  img {
    display: block;
    width: 100%;
    border-radius: var(--border-radius-1);
    flex-grow: 0;
    flex-shrink: 0;
  }
}

@media only screen and (width >= $tablet-portrait) {
  .EmployeeCard {
    padding: var(--spacing-3);
  }

  .EmployeeCardBody {
    --image-width: 16rem;
    grid-template-columns: auto var(--image-width);
    gap: var(--spacing-3);

    picture,
    img {
      width: var(--image-width);
    }
  }
}
