.Tooltip {
  background-color: var(--color-secondary-1);
  color: #fff;
  padding: 0.8rem 1.6rem;
  border-radius: 0.8rem;
  transform: translate(0, 0);

  // Arrow.
  &::after {
    content: '';
    width: 0;
    height: 0;
    overflow: hidden;
    text-indent: -9999px;
    position: absolute;
    bottom: 1px;
    right: 0;
    transform: translate(-2rem, 0.8rem);
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: 0.8rem solid var(--color-secondary-1);
  }
}
