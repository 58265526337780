@import '../../styles/variables';
@import '../../styles/util';

.Item {
  text-align: left;
  background-color: #fff;
  border-radius: var(--border-radius-1);
  box-shadow: var(--box-shadow-1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  picture {
    background-color: #c9d2ba;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    height: 16rem;
    padding: 0.8rem;
  }

  img {
    flex-shrink: 0;
    object-fit: contain;
    height: 100%;
  }
}

.ItemBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-2);
  gap: var(--spacing-2);
  height: 100%;

  h3 {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 500;

    a {
      color: var(--color-dark-1);
    }
  }

  p {
    font-size: 1.4rem;

    &.clamped {
      @include line-clamp(5);
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
  }

  a {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
  }

  button {
    font-size: 13px;
    text-align: right;
    color: var(--color-primary-1);
  }

  div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media only screen and (width >= $tablet-portrait) {
  .ItemBody {
    padding: var(--spacing-2);
  }
}
