.Item {
  display: block;
  background-color: #fff;
  text-align: start;
  box-shadow: var(--box-shadow-1);
  border-radius: var(--border-radius-1);
  overflow: hidden;
  height: 100%;

  img {
    aspect-ratio: 24 / 20;
    width: 100%;
  }
}

.ItemBody {
  padding: var(--spacing-2);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  p {
    font-size: 1.4rem;
  }

  .ItemFunction {
    font-size: 1.3rem;
    font-style: italic;
    margin-top: -1.25rem;
  }
}
