@import '../../styles/variables';
@import '../../styles/util';

.Item {
  display: block;
  background-color: #fff;
  text-align: start;
  box-shadow: var(--box-shadow-1);
  border-radius: var(--border-radius-1);
  overflow: hidden;

  picture,
  img {
    aspect-ratio: 16 / 9;
    width: 100%;
    background-color: #ddd;
    display: block;
  }
}

.ItemBody {
  padding: var(--spacing-3);
  gap: var(--spacing-2);
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.4rem;

    &.clamped {
      @include line-clamp(5);
    }
  }

  button {
    font-size: 13px;
    text-align: right;
    color: var(--color-primary-1);
  }
}
