@use 'sass:math';

$line-width: 14px;
$line-width-2: ($line-width * 2);
$line-offset: math.hypot($line-width-2, $line-width-2) * -1;

.AudioPlayer {
  position: relative;
  background-color: var(--color-secondary-1);
  color: #fff;
  padding: var(--spacing-2);
  display: grid;
  gap: var(--spacing-2);
  isolation: isolate;

  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    inset: 0;
    background: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.1) 0,
      rgba(255, 255, 255, 0.1) $line-width,
      rgba(255, 255, 255, 0) $line-width,
      rgba(255, 255, 255, 0) $line-width-2
    );
    background-repeat: repeat;
    background-attachment: fixed;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    pointer-events: none;
  }

  &.loading::before {
    opacity: 0.4;
    animation: moveLines 300ms linear infinite;
  }
}

@keyframes moveLines {
  from {
    background-position-x: 0;
  }

  to {
    background-position: $line-offset;
  }
}

.Controls {
  display: grid;
  grid-template-columns: 1fr 2rem 4rem 2rem 1fr;
  align-items: center;
  gap: 0;
  width: 100%;
  gap: var(--spacing-2);
  font-size: 1.2rem;
  line-height: 1.2rem;
  text-align: right;

  .Button {
    width: 2rem;
    height: 2rem;
    background: #fff;
  }

  a {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-1);
  }
}

.Time {
  display: grid;
  grid-template-columns: 6rem 1fr 6rem;
  font-size: 1.4rem;
  line-height: 1.4rem;
  align-items: center;
  gap: var(--spacing-1);
  color: #93a3b2;
}
