@import '../../styles/variables';
@import '../../styles/util';

.Filters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);
  justify-content: center;
  width: 100%;

  & > * {
    flex: 1;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='date'],
  input[type='number'] {
    width: 100%;
    min-width: 180px;
    border: 1px solid var(--color-secondary-1);
    background: transparent;
  }

  select {
    background-color: transparent;
    border: 1px solid var(--color-secondary-1);
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Filters {
    width: auto;
    max-width: max-content;
    gap: var(--spacing-2);
  }
}
