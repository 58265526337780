@import '../../styles/variables';
@import '../../styles/util';

.Item {
  --image-size: 11.6rem;
  text-align: left;
  background-color: #fff;
  border-radius: var(--border-radius-1);
  box-shadow: var(--box-shadow-1);
  overflow: hidden;
  max-width: 55rem;

  picture,
  img {
    background-color: #ccc;
    aspect-ratio: 1 / 1;
    height: auto;
    width: 100%;
    width: var(--image-size);
    height: var(--image-size);
  }
}

.ItemSummary {
  display: grid;
  grid-template-columns: var(--image-size) auto;
  position: relative;
  border-radius: var(--border-radius-1);
  overflow: hidden;
}

.ItemDetails {
  padding: 0.8rem 1.6rem 1.6rem 13.2rem;
  display: none;
  flex-direction: column;
  gap: 1.6rem;

  button {
    align-self: flex-end;
  }

  &.expanded {
    display: flex;
  }
}

.ItemBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4rem 0 1.6rem;
  gap: 0.8rem;

  h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    @include line-clamp(1);
  }

  a {
    color: var(--color-dark-1);
    text-decoration: none;
  }

  span {
    font-size: 1.4rem;
    @include line-clamp(1);
    padding-right: 3.5rem;
  }
}

.ItemButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1.6rem 1.6rem 1.5rem 0;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;

  button {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-primary-1);
  }
}
