.Iframe {
  position: relative;
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
}

.Iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
